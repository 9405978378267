import React, { useCallback, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { Input, CheckboxInput, Select } from "components/shared/Form";
import { MainContainer } from "components/shared";
import { useValidation, useTranslation } from "hooks";
import { translations } from "./translations";
import { GroupedPermission } from "interfaces/group";
import {
  CreateGroupActions,
  CreateGroupState,
} from "store/ducks/settings/groups";
import {
  PaginatePermissionsState,
  PaginatePermissionsActions,
} from "store/ducks/settings/permissions";
import { ListModulesActions, ListModulesState } from "store/ducks/modules";

import * as S from "./styles";

export const NewGroup: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getTranslation } = useTranslation(translations);
  const [selectedModule, setSelectedModule] = useState<string>();
  const [groupedPermission, setGroupedPermission] = useState<GroupedPermission>(
    {}
  );

  const { loading: loadingModules, data: dataModules } = useSelector<
    RootStateOrAny,
    ListModulesState
  >((state) => state.listModules);

  const { data: dataPermissions } = useSelector<
    RootStateOrAny,
    PaginatePermissionsState
  >((state) => state.paginatePermissions);

  const { loading } = useSelector<RootStateOrAny>(
    (state) => state.createAlert
  ) as CreateGroupState;

  const onSuccess = useCallback(() => {
    history.push("/settings/groups");
  }, [history]);

  const handleSubmit = useCallback<SubmitHandler>(
    async (data) => {
      try {
        const { name } = data;
        delete data.general_module_id;
        delete data.name;

        const permissionIds = Object.values(data)
          .flat()
          .map((permissionId) => permissionId);

        formRef.current?.setErrors({});

        const newData = {
          name,
          permissionIds,
        };
        const schema = Yup.object().shape({
          name: Yup.string().required("Obrigatório"),
        });

        await schema.validate(newData, {
          abortEarly: false,
        });
        dispatch(CreateGroupActions.request(newData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, handleFormErrors, onSuccess]
  );

  const getListModules = useCallback(() => {
    dispatch(ListModulesActions.request({ all: true }));
  }, [dispatch]);

  const getPermissions = useCallback(() => {
    dispatch(PaginatePermissionsActions.request({ all: true }));
  }, [dispatch]);

  const onHandleChangeModule = useCallback((data) => {
    setSelectedModule(String(data.value));
  }, []);

  const onHandleBuildPermission = useCallback(() => {
    const listPermission: GroupedPermission = {};

    dataPermissions?.forEach((item) => {
      if (!listPermission.hasOwnProperty(`${item.domain}`)) {
        Object.assign(listPermission, { [`${item.domain}`]: [] });
      }

      listPermission[`${item.domain}`].push({
        id: `${item?.id}`,
        label: item?.name,
        value: `${item?.id}`,
        module_id: `${item.module.id}`,
        description: item.description,
      });
    });

    setGroupedPermission(() => listPermission);
  }, [dataPermissions]);

  useEffect(() => getListModules(), [getListModules]);
  useEffect(() => getPermissions(), [getPermissions]);

  useEffect(() => {
    if (dataPermissions) {
      onHandleBuildPermission();
    }
  }, [dataPermissions, onHandleBuildPermission]);

  return (
    <MainContainer>
      <S.PageHeader>
        <h1>
          <S.IconSetting />
          {getTranslation("configuracoes")}
          <span>{getTranslation("novoPerfilUsuario")}</span>
        </h1>
        <S.HeaderButtons>
          <S.ButtonMini btStyle="dark" onClick={() => history.goBack()}>
            <S.IconArrowLeft />
            {getTranslation("voltar")}
          </S.ButtonMini>
        </S.HeaderButtons>
      </S.PageHeader>
      <S.PageContent>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <S.BoxContainer>
            <S.FormRow>
              <Input name="name" label={getTranslation("nome")} />
            </S.FormRow>
            <S.Title>{getTranslation("permissoesPerfil")}</S.Title>
            <S.FormRow>
              <Select
                name="general_module_id"
                label={getTranslation("modulo")}
                isLoading={loadingModules}
                isDisabled={loadingModules}
                options={dataModules}
                onChange={onHandleChangeModule}
              />
            </S.FormRow>
            <S.CheckContainer>
              {Object.entries(groupedPermission).map(
                ([domain, permissions], i) => {
                  return (
                    <CheckboxInput
                      hideInList={
                        !(
                          selectedModule &&
						  permissions[0].module_id === selectedModule
                        )
                      }
                      direction="column"
                      fixWidth="165px"
                      key={domain}
                      options={permissions}
                      name={domain}
                      label={domain}
                    />
                  );
                }
              )}
            </S.CheckContainer>
          </S.BoxContainer>

          <S.FormFooter>
            <S.FormRow>
              <S.Button
                btStyle="cancel"
                type="button"
                onClick={() => history.goBack()}
              >
                {getTranslation("cancelar")}
              </S.Button>
              <S.Button type="submit">
                {loading ? <S.Loading /> : getTranslation("cadastrar")}
              </S.Button>
            </S.FormRow>
          </S.FormFooter>
        </Form>
      </S.PageContent>
    </MainContainer>
  );
};
